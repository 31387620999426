.CodeMirror {
  height: 100vh !important;
}

.CodeMirror-code {
  margin-bottom: 20px; 
}

.button-container {
  display: flex;
  position: absolute;
  top: 95%;
  left: 98%;
  transform: translate(-98%, -95%);
  z-index: 10;
}

.wrapper {
  position: relative;
  box-sizing: border-box;
}

.btn {
  display: block;
  height: 50px;
  width: 186px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;
  font-size: 14px;
  text-align: center;
}

.btn::after {
  position: absolute;
  content: "";
  display: inline-block;
  background: rgba(59, 173, 227, 1);
  background: linear-gradient(
    45deg,
    rgba(59, 173, 227, 1) 0%,
    rgba(87, 111, 230, 1) 25%,
    rgba(152, 68, 183, 1) 51%,
    rgba(255, 53, 127, 1) 100%
  );
  height: 50px;
  width: 372px;
  z-index: -1;
  transform: translateX(-280px);
  transition: transform 400ms ease-in;
}

.btnCopy {
  width: 100px;
  margin-right: 10px;
}

.btnCopy::after {
  background: rgba(59, 173, 227, 1);
  background: linear-gradient(
    45deg,
    rgb(59, 227, 163) 0%,
    rgb(87, 201, 230) 25%,
    rgb(68, 128, 183) 51%,
    rgb(56, 53, 255) 100%
  );
}

.btn:hover::after {
  transform: translateX(-200px);
}

.btn span {
  position: relative;
  top: 16px;
}

.btn:active {
  transform: scale(0.98, 0.95);
}

.iconsContainer {
  position: absolute;
  display: flex;
  align-items: center;
  height: 35px;
  top: 20px;
  right: 25px;
  z-index: 2;
}

.iconsContainer > a {
  margin: 0 4px;
  padding: 4px;
  background-color: #414458;
  border-radius: 5px;
  transition: transform 0.3s;
}

.iconsContainer > a:hover {
  transform: translateY(-5px);
}
